/* global $ */
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { init } from 'cookie-though';
import { onPreferencesChanged } from 'cookie-though';
import { show } from 'cookie-though';
class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initSlider();
        this.initVideo();
        this.initCookiePreference();
        this.initShowPreference();
        this.checkPreferences();
        $('body').css('opacity', 1);
        $('.actionDelete').click(this.deleteElement);
        console.log('App initialized');
    }

    initCookiePreference() {
        var config = {
            "policies": [
                {
                    "id": "essential",
                    "label": "Essential Cookies",
                    "description": "These are cookies are necessary to provide an online service on a website or used solely to carry out or facilitate the transmission of communications over a network.",
                    "category": "essential",
                },
                {
                    "id": "analytics",
                    "label": "Analytics",
                    "category": "analytics",
                    "description": "Analytics cookies or performance cookies are used to track website visitors and their user behaviour. This data is then used to improve the way the website works and in turn, used to improve user experience.",
                }
            ],
            "essentialLabel": "Always on",
            "permissionLabels": {
                "accept": "ACCEPT",
                "acceptAll": "ACCEPT ALL",
                "decline": "DECLINE"
            },
            "cookiePreferenceKey": "cookie-preferences",
            "header": {
                "title": "We use cookies",
                "subTitle": "This website uses cookies to ensure you get the best experience on our website. ",
                "description": "You can choose what cookies you want to keep but we reccomend to keep all cookies."
            },
            "cookiePolicy": {
                "url":"/postcodelottery/privacy-policy",
                "label":"Read the full cookie declaration",
            },
            "customizeLabel": "Customize"
        };
        init(config);
    }
    checkPreferences() {
        onPreferencesChanged(()=> {
            location.reload(true)
        })
    }
    initShowPreference() {
        $('#cookieprefshow').on('click', function(){
            show();
        });
    }

    deleteElement()
    {
        if (confirm('Are you sure you want to delete this item?')) {
            const entityType = $(this).data('entityType'),
                entityId = $(this).data('entityId'),
                entityParent = $(this).parents('.deleteParent');

            if (entityType && entityId) {
                $.ajax({
                    url: '/?action=voucher-portal/utilities/delete-element',
                    data: {
                        entityType,
                        entityId
                    },
                    method: 'post',
                    dataType: 'json',
                    headers: {
                        "X-CSRF-Token": window.Globals.csrfToken
                    }
                }).done(() => {
                    entityParent.hide();
                }).fail(() => {
                    alert('Could not delete this item');
                });
            }
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    addToast(message, type = "success", autoHide = true)
    {
        let _class = "toast align-items-center fs-6 border-0 mt-2 bg-" + type;
        let toast = $('<div class="' + _class + '" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="' + (autoHide ? 'true' : 'false') + '"><div class="d-flex"><div class="toast-body">' + message + '</div><button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
        toast.appendTo($('#global-messages'));
        this.initToasts(toast);
    }

    initSlider() {
        var swiper = new Swiper(".thumb-slider", {
            loop: true,
            spaceBetween: 10,
            slidesPerView: 4,
            watchSlidesProgress: true,
            speed: 1000,
        });
        new Swiper(".gallery-slider", {
            loop: true,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            speed: 1000,
            thumbs: {
                swiper: swiper,
            },
        });
    }

    initVideo() {
        if ($('.video-container').length) {
            $('.video-container').each(function() {
                $(this).click(() => {
                    let videoCont = $(this).find('.vdo-media');
                    let videoFrame = videoCont.find('iframe');
                    let videoUrl = videoCont.data('video');
                    videoCont.css('z-index', '3');
                    videoFrame.attr('src', videoUrl);
                });
            });
        }
    }
}

export default App;
